import { useRouter } from 'next/router'
import React, { FC, useState } from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, priceFormat } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { useReInitializeCartByOrder } from 'lib/actions'
import { useAppContext } from 'lib/contexts/app-context'
import { checkRoleBasedProperties } from 'lib/utilities'

export interface IOrderHistorySummeryCardProps {
  className?: string
  gst: number
  isEnableReOrder: boolean
  orderId: string
  shippingCharges: number
  subTotal: number
}

export const OrderHistorySummeryCard: FC<IOrderHistorySummeryCardProps> = ({
  className,
  gst,
  isEnableReOrder,
  orderId,
  shippingCharges,
  subTotal
}: IOrderHistorySummeryCardProps) => {
  const { user, cart } = useAppContext()

  const router = useRouter()

  const { mutateAsync: reInitializeCartByOrderAsync, isPending: isLoadingReOrder } =
    useReInitializeCartByOrder({
      cartId: cart?.cartId,
      orderNumber: orderId
    })

  const [isOpenReOrderModal, setIsOpenReOrderModal] = useState(false)

  const { showGstPricePrimary, showShippingCharges } = checkRoleBasedProperties(user?.data?.roles)

  const componentId = 'my-order-history-details-order-summery-card'

  const OrderHistorySummeryCardClasses = CN(
    `${componentId} flex flex-col w-full md:!w-[295px] h-fit`,
    className
  )

  // Opening Re Order modal
  const openReOrderModal = () => {
    setIsOpenReOrderModal(true)
  }

  // Closing Re Order modal
  const closeReOrderModal = () => {
    setIsOpenReOrderModal(false)
  }

  const reOrder = () => {
    reInitializeCartByOrderAsync().then(() => {
      closeReOrderModal()
      router.push('/cart')
    })
  }

  const orderTotal = subTotal + gst + (showShippingCharges ? shippingCharges : 0)

  return (
    <div className={OrderHistorySummeryCardClasses}>
      <div className='flex flex-col gap-[12px] bg-white px-[16px] pb-[16px]'>
        <div className={`${componentId}-subTotal flex flex-row justify-between`}>
          <span className={`${componentId}-subTotal-text text-sm font-normal text-N-700`}>
            Sub-total
          </span>

          {!!subTotal && (
            <span className={`${componentId}-subTotal-value text-sm font-medium text-N-700`}>
              ${priceFormat(subTotal)}
            </span>
          )}
        </div>
        {showShippingCharges && (
          <div className={`${componentId}-shipping-charges flex flex-row justify-between text-sm`}>
            <span className={`${componentId}-shipping-charges-text text-sm font-normal text-N-700`}>
              Shipping Charges
            </span>

            <span
              className={`${componentId}-shipping-charges-value text-sm font-medium text-N-700`}>
              ${priceFormat(shippingCharges ?? 0)}
            </span>
          </div>
        )}

        <div className={`${componentId}-gstPrice flex flex-row justify-between`}>
          <span className={`${componentId}-gstPrice-text text-sm font-normal text-N-700`}>GST</span>

          {!!gst && (
            <span className={`${componentId}-gstPrice-value text-sm font-medium text-N-700`}>
              ${priceFormat(gst)}
            </span>
          )}
        </div>
      </div>

      <div className='flex flex-col bg-B-25 px-[16px] py-[16px]'>
        <div className={`${componentId}-orderTotal flex flex-row justify-between`}>
          {showGstPricePrimary ? (
            <span className={`${componentId}-orderTotal-text text-sm font-bold text-N-700`}>
              Order Total (incl.GST)
            </span>
          ) : (
            <span className={`${componentId}-orderTotal-text text-sm font-bold text-N-700`}>
              Order Total
            </span>
          )}

          <span className={`${componentId}-orderTotal-value text-sm font-bold text-N-700`}>
            ${priceFormat(orderTotal)} (NZD)
          </span>
        </div>
      </div>

      <Button
        data-component-id='my-order-history-order-details-re-order-btn'
        componentId='my-order-history-order-details-re-order-btn'
        onClick={() => {
          openReOrderModal()
        }}
        className='w-full text-center mt-8'
        size='lg'
        appearance='primary-orange'
        disabled={!isEnableReOrder}>
        RE-ORDER
      </Button>

      {/* Re-Order Modal */}
      <Modal
        componentId='order-history-re-order-modal'
        isOpen={isOpenReOrderModal}
        className='z-20 max-w-[380px] md:!max-w-[514px]'>
        <ModalHeader
          componentId='order-history-re-order-modal'
          hasBorder={false}
          heading={null}
          onClose={closeReOrderModal}
        />
        <ModalBody componentId='order-history-re-order-modal' className='p-[20px] pt-0'>
          <div className='flex flex-col items-center justify-center text-center quick-cart-modal-body-confirmation'>
            <i className='quick-cart-modal-body-confirmation-icon ri-error-warning-line text-[48px] text-O-500 mb-[16px]' />
            <span className='text-left'>
              When selecting re-order, some products which are not available for ordering via our
              website will not be included in your cart. Please contact us on 0800 660 660 to assist
              you with these items if required.
            </span>
          </div>
        </ModalBody>
        <ModalFooter componentId='order-history-re-order-modal'>
          <div className='flex gap-[16px]'>
            <Button
              componentId='order-history-re-order-modal-cancel-btn'
              appearance='secondary-gray'
              onClick={closeReOrderModal}>
              CANCEL
            </Button>
            <Button
              componentId='order-history-re-order-modal-reorder-btn'
              appearance='primary-orange'
              className='mr-2'
              isLoading={isLoadingReOrder}
              onClick={reOrder}>
              RE-ORDER
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default OrderHistorySummeryCard
